import React from 'react'


const Sidebar = ({ children }) => {

  return (
    <div className="col-4 col-12-medium">
      <section id="sidebar">
        {children}
      </section>
    </div>
  )
}

export default Sidebar
