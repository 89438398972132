import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import Heading from '../components/Heading'
import Sidebar from '../components/Sidebar'
import SidebarContent from '../components/Sidebar-Content'
import Image from '../components/Image'

import pic06 from '../assets/images/DSC01278.jpg'


const RightSidebar = props => (
  <Layout>
    <Helmet>
      <title>Plates - Pilates Classes and Pricing</title>
      <meta name="description" content="Class List, Class Schedule, and Pricing Information for Plates Pilates" />
      <link rel="canonical" href="https://www.plates-pilates.com/offerings" />
    </Helmet>

    <div id="main" className="wrapper style1">
      <div className="container">
        <Heading
          class="major"
          title="Our Offerings"
          text="Class Descriptions and Pricing Info"
        />
        <div className="row gtr-150" id="classes">
          <SidebarContent>
              <h3>Classes</h3>
              <Image src={pic06} alt="Plates Pilates Logo" fit />
              <header>
                <h4>Reform and Rebound</h4>
                <p>*** Required: Reformer with rebounder or jumpboard <a href="https://staminaproducts.com/product/aeropilates-reformer-651-a/">
                  (Buy Reformer)
                  </a>
                </p>
              </header>
              <p>
                The perfect combination of cardio, strength, and flexibility.
                The Pilates jumpboard builds cardiovascular endurance while
                supporting the joints with the reformer’s customized spring
                tension. Reform and rebound combines the best exercises from both
                classic and contemporary Pilates leaving clients with that
                “post-workout glow” typically only achieved at the studio.
              </p>
              <header>
                <h4>Roll with it</h4>
                <p>*** Required: Foam Roller <a href="https://www.amazon.com/ProSource-Half-Round-Physical-Exercises-Stabilization/dp/B00EDTEIAM/ref=sr_1_31?crid=3SQZPVSEHT72D&dchild=1&keywords=foam+roller&qid=1584793538&sprefix=foam+%2Caps%2C191&sr=8-31%22https://www.amazon.com/ProSource-Half-Round-Physical-Exercises-Stabilization/dp/B00EDTEIAM/ref=sr_1_31?crid=3SQZPVSEHT72D&dchild=1&keywords=foam+roller&qid=1584793538&sprefix=foam+%2Caps%2C191&sr=8-31">
                    (Buy Foam Roller)
                  </a>
                </p>
              </header>
              <p>
                The foam roller does it all! This unstable  prop challenges
                core stability and balance. It also provides release for tension
                and sore muscles. Clients will tone it up and roll it out in
                this 60 minute Pilates mat inspired class.
              </p>
              <header>
              <h4>Band Together</h4>
                <p>*** Required: TheraBand or resistance band <a href="https://www.amazon.com/TheraBand-Professional-Resistance-Individual-Intermediate/dp/B006M95RX8/ref=sr_1_11?crid=1YFSE0QU0AWCH&keywords=therabands&qid=1584794952&sprefix=therabands%2Csporting%2C161&sr=8-11">
                    (Buy TheraBand)
                  </a>
                </p>
              </header>
              <p>
                The Theraband provides both support and resistance in this
                Pilates mat class flow. Band Together will focus on the muscles
                of the back and shoulders to improve posture and create long
                lean lower body muscle tone.
              </p>
              <header>
              <h4>Slip and Glide</h4>
                <p>*** Suggested: Glider <a href="https://www.amazon.com/Iron-Core-Fitness-Abdominal-Equipment/dp/B019FEM84M/ref=sr_1_15?dchild=1&keywords=fitness+gliders+discs&qid=1584794677&refinements=p_85%3A2470955011&rnid=2470954011&rps=1&sr=8-15&swrs=2BDE51B59EC7B7E948AAA4F257902A5D">
                    (Buy Gliders)
                  </a> Alternatively: Use towels or paper plates on a non-carpet floor.
                </p>
              </header>
              <p>
                Slip and Glide combines Pilates reformer exercises with traditional
                barre exercises. Clients will improve balance and
                increase overall muscle tone with a focus on control and precision.
              </p>

              <header>
              <h4>Bar to Resist</h4>
                <p>*** Required: Pilates Bar Kit <a href="https://www.target.com/p/gaiam-pilates-bar-kit/-/A-53593871?ref=tgt_adv_XS000000&AFID=bing_pla_df&CPNG=PLA_Sports%2BShopping&adgroup=SC_Sports&LID=700000001230728pbs&network=s&device=c&querystring=gaiam%20pilates%20bar%20kit&msclkid=59098a1833d8173dd6b5d49a0576c175&gclid=CIzTm7vmwugCFQnJswodDtsHKw&gclsrc=ds">
                    (Buy Pilates Bar)
                  </a>
                </p>
              </header>
              <p>
                Bar to Resist is the next best thing to having a Pilates tower
                in your home. Clients will use the bar and the resistance cords
                to complete roll-back bar, arm and leg spring exercises. Expect
                a full body workout as we take the mat work to the next level.
              </p>
              <header>
              <h4>Magic Circle</h4>
                <p>
                  *** Required: Magic Circle
                </p>
              </header>
                <p>
                  The Magic circle supports clients in finding support of the
                abdominals and the pelvic floor when used in the classical mat
                work. Not to mention… a terrific burn for the inner thighs.
                Clients will feel centered as we focus inward to activate the
                deepest core muscles.
              </p>
              <h4>Private Sessions</h4>
                <p>
                  Private instruction is a one hour session customized to address the
                  student's goals. These sessions will include personal correction
                  and feedback, tailored instruction, and will work with any and all
                fitness props and equipment the client owns at home. Whether you are looking
                to finesse your Pilates practice, rehab from injury, or add variety to
                your fitness routine, we have you covered.
              </p>
          </ SidebarContent>
          <Sidebar>
              <section id="schedule">
                <h3>Schedule</h3>
                <div className="table-wrapper">
                  <table>
                    <thead>
                      <tr>
                        <th>Day</th>
                        <th>Time</th>
                        <th>Class</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Monday</td>
                        <td>10 am</td>
                        <td>Roll with it</td>
                      </tr>
                      <tr>
                        <td>Tuesday</td>
                        <td>9:30 Am</td>
                        <td>Slip and Glide</td>
                      </tr>
                      <tr>
                        <td>Tuesday</td>
                        <td>5:30 Pm</td>
                        <td>Roll with it</td>
                      </tr>
                      <tr>
                        <td>Thursday</td>
                        <td>12 Pm</td>
                        <td>Bar to Resist</td>
                      </tr>
                      <tr>
                        <td>Thursday</td>
                        <td>5:30 Pm</td>
                        <td>Band Together</td>
                      </tr>
                      <tr>
                        <td>Saturday</td>
                        <td>10 Am</td>
                        <td>Bar to Resist</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </section>

              <section id="pricing">
                <h3>Pricing</h3>
                <ul className="alt">
                  <li>Mat Single - $10</li>
                  <li>Reformer Single - $20</li>
                  <li>Reformer 5 pack - $75</li>
                  <li>Private Session - $50</li>
                  <li>Reform at Home - $300</li>
                </ul>
                <footer>
                  <h3>Payment</h3>
                  <p>Venmo: @platespilates <br />
                  Please specify the service you are purchasing in 'notes'</p>
                </footer>
              </section>
          </Sidebar>
        </div>
      </div>
    </div>
  </Layout>
)

export default RightSidebar
