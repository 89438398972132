import React from 'react'


/*
 * Returns an image (possibly in a row container) depending on passed props
 *
 * @src {string} - src attribute of image to display
 * @alt {string} - alt text to display for the image
 * @size {string} - 'large' or 'small' for adding pictures to row
 * @left {boolean} - Inline image to the Left
 * @right {boolean} - Inline image to the right
 * @fit {boolean} - Size image to it's container
 * @main {boolean} - Images on the landing page
 * @last {boolean} - For the last image on the landing page
 *
 * Provides an inline image if 'left' or 'right' are provided
 * and if fit is provided big takes a whole row while small fits 2-3 per row
 */
const Image = props => {
  var classString = "image"
  const size = props.size === 'large' ? 'col-12' : 'col-4 col-6-xsmall'

  if (props.fit) {classString += " fit"}
  if (props.left) {classString += " left"}
  if (props.right) {classString += " right"}
  if (props.main) {classString += " main"}
  if (props.last) {classString += " bottom"}

  var element = <span className={classString}><img src={props.src} alt={props.alt} /></span>

  if (props.fit && !props.main) {
    return (<div className={size}>
             {element}
           </div>
    )
  } else {
    return element
  }
}

export default Image
