import React from 'react'


const SidebarContent = ( {children} ) => (
  <div className="col-8 col-12-medium imp-medium">
    <section id="content">
      {children}
    </section>
  </div>
)

export default SidebarContent
